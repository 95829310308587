import ReactDOM from 'react-dom';
import { cleanupLocalStorage as cleanupLocalStorageLD } from '@90poe/feature-flags';
import journalEngine, { Severity, oosLogger, initSentry } from '@90poe/journal';
import { type AuthTokenData, auth } from '@90poe/auth';
import { APP_STATUS_NOT_ALLOWED, config } from '@90poe/oos-config';
import {
    createStore,
    cleanupLocalStorage as cleanupLocalStorageApollo,
} from '@90poe/shared-state';

import { authorizeUserAndGetToken } from './sharedServices/auth';
import { initRealtimeDataObservers } from './sharedServices/realtimeData';
import { logError } from './helpers/logger';
import i18nModule from './locales/module';
import { initAnalytics } from './sharedServices/analytics';
import { initStreamChat } from './sharedServices/streamChat';
import { registerApps } from './mfeComposer';
import { type SharedServices } from './types';
import { App } from './reactApp/App';

// Styles
// antd v3 styles, for MFEs that use oos-ui and need global antd v3 styles
import './styles/global.less';
import '../public/favicons';

export const ERROR_MESSAGES = {
    NO_TOKEN: 'Failed to retrieve the auth token.',
    FAILED_TO_INITIALIZE: 'Failed to init app.',
    USER_NOT_SET_IN_SHARED_STORE: 'User is not set in the shared store.',
};

export async function setup(): Promise<void> {
    journalEngine.init({ mfe: __mfe, logger: oosLogger });
    initSentry();

    const token = await authorizeUserAndGetToken().catch((reason: unknown) => {
        logError({
            uuid: '4dd7098c-3356-4674-8f25-ad534e3ecc1b',
            message: ERROR_MESSAGES.FAILED_TO_INITIALIZE,
            level: Severity.Error,
            data: { reason },
        });
    });

    if (!token) {
        logError({
            uuid: 'b1e4ef26-e361-498f-93d6-21b5c55d7a8c',
            message: ERROR_MESSAGES.NO_TOKEN,
            level: Severity.Error,
        });
        return;
    }

    await bootstrap(token);
}

async function bootstrap(token: AuthTokenData) {
    try {
        if (localStorage.getItem('isAuthenticated') === 'false') {
            window.location.reload();
        }

        // This doesn' detect the situation when the browser is closed and opened again
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                console.log('Tab is active!');
                const now = new Date();
                const previousInactive =
                    sessionStorage.getItem('tabSetInactive');
                if (previousInactive) {
                    const previousInactiveDate = new Date(previousInactive);
                    const diff = now.getTime() - previousInactiveDate.getTime();
                    console.log(
                        `Tab was inactive for ${diff} milliseconds, which is ${
                            diff / 1000
                        } seconds`,
                    );
                    // const reloadTreshold = 2 * 3600 * 1000; // 2 hours
                    const reloadTreshold = 5 * 60 * 1000; // 5 min
                    if (diff > reloadTreshold) {
                        console.log('Tab was inactive for more than 2 hours');
                        window.location.reload();
                    }
                }
            } else {
                console.log('Tab is inactive!');

                sessionStorage.setItem(
                    'tabSetInactive',
                    new Date().toISOString(),
                );
            }
        });

        window.addEventListener('storage', (event) => {
            if (event.key === 'isAuthenticated' && event.newValue === 'false') {
                window.location.reload();
            }
        });

        const sharedServices = initSharedServices(token);

        await sharedServices.sharedStore.user.setUser(token);
        const user = sharedServices.sharedStore.user.getUser();
        if (!user) {
            logError({
                uuid: '4ec56aa4-759b-498a-8000-2cb6bcc5397a',
                message: ERROR_MESSAGES.USER_NOT_SET_IN_SHARED_STORE,
                level: Severity.Error,
            });
            await sharedServices.sharedStore.appStatus.setAppStatus(
                APP_STATUS_NOT_ALLOWED,
            );
            return;
        }

        // FIXME: it will cleanup the local storage LD entries with keys > 2000 characters
        // https://ninetypercent.atlassian.net/browse/PFE-1700
        cleanupLocalStorageLD();
        // FIXME: it will cleanup the local storage from apollo cache persistence (ROOT cache key)
        // After Shell with this code will be deployed to all environments, wait a few weeks and remove this line
        // https://ninetypercent.atlassian.net/browse/PFE-2084
        cleanupLocalStorageApollo();

        const registerAppsFn = () => registerApps(user, sharedServices);

        const root = document.getElementById('root');
        if (root) {
            ReactDOM.render(
                <App
                    sharedStore={sharedServices.sharedStore}
                    realtimeDataObservers={sharedServices.realtimeDataObservers}
                    streamChatRoot={sharedServices.streamChatRoot}
                    registerMFEs={registerAppsFn}
                />,
                root,
            );
        }
    } catch (error) {
        // TODO: This catch have no corresponding tests now, as it looks as nothing happens here apart from console log
        // eslint-disable-next-line no-console
        console.error(
            'Error ab04c76f-45e7-41f3-abc0-7afb0bf37b0a: Failed to initialize Single SPA.',
            error,
        );
    }
}

function initSharedServices(token: AuthTokenData): SharedServices {
    const realtimeDataObservers = initRealtimeDataObservers(token, {
        notificationsEnabled: config.NOTIFICATIONS_ENABLED,
        queriesAndMutationsUri:
            config.CLIENT_API_NOTIFICATIONS_QUERIES_AND_MUTATIONS, // TODO: this uri is not used!!!
        subscriptionsUri: config.CLIENT_API_NOTIFICATIONS_SUBSCRIPTIONS,
        serviceToken: auth.getServiceToken(),
    });

    const sharedStore = createStore();

    initAnalytics(token, {
        analyticsAppId: config?.ANALYTICS_HEAP_APP_ID,
        getServiceTokenFunc: auth.getServiceToken,
    });

    const streamChatRoot = initStreamChat();

    const i18n = {
        modules: [i18nModule],
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    return {
        realtimeDataObservers,
        sharedStore,
        streamChatRoot,
        i18n,
    };
}
